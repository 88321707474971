<template>
  <div id="taskSend">
    <div class="content-container-roleManage">
      <top-header>
        <div slot="setup-header-left" class="roleManage-header">
          <div class="leftInfo">
            <span class="setup-header-left-icon"><i class="iconfont guoran-tongyichicun-renwuguanli"></i></span>
            <span class="headerName">{{$t('massSend.headerName')}}</span>
            <span class="split-line"></span>
            <span class="header-tip">{{$t('massSend.headerTip')}}</span>
          </div>
        </div>
        <div slot="setup-header-right-l" class="setup-header-right-l">
          <span class="setup-header-right-l-btn" @click="addTaskClick" v-if="activeTab==='massSend'">
            <i class="iconfont guoran-a-16-13"></i>
            {{$t('massSend.createMass')}}
          </span>
          <span class="setup-header-right-l-btn" @click="addRg" v-else-if="activeTab==='autotag'">
            <i class="iconfont guoran-a-16-13"></i>
            {{$t('massSend.addAutoTag')}}
          </span>
        </div>
      </top-header>
      <div class="roleManage-content">
        <div class="roleManage-content-left" v-if="false">
          <div class="side-bat-list">
            <div v-for="(item,index) in leftSide"
                 :key="index"
                 class="side-bar-item"

                 @click="checkSideBar(item)"
            >
              <span :class="item.isActive?'active-side-bar':''"
                    class="side-bar-item-name"
              >
                 <i class="iconfont " :class="item.icon"></i>
                {{item.name}}
              </span>
            </div>
          </div>
        </div>
        <div class="roleManage-content-right">
          <mess-send-table v-if="activeTab==='massSend'"
                           @handleClick="handleClick"
          ></mess-send-table>
          <autotag v-else-if="activeTab==='autotag'" ref="autoTag"></autotag>
        </div>
        <el-tabs id="left-sub-nav-tab" tab-position="left" v-model="activeTab" @tab-click="tabClick" v-if="false">
          <el-tab-pane label="群发任务" name="massSend">
            <div class="right-content">
              <div class="tast-list-box">
                <div class="task-list-header">
                  <div class="search-box"></div>
                  <div class="operation-box">
                    <el-button
                        size="mini"
                        type="primary"
                        @click="addTaskClick"
                    >新增任务
                    </el-button
                    >
                  </div>
                </div>
                <div class="task-list-content">
                  <el-table
                      :data="tableData"
                      height="calc(100vh - 200px)"
                      style="width: 100%"
                  >
                    <el-table-column
                        prop="name"
                        label="任务名称"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="channel"
                        align="center"
                        label="发送渠道"
                        min-width="80"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{
                            scope.row.channel | channel
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <!--                                    <el-table-column-->
                    <!--                                        min-width="80"-->
                    <!--                                        prop="sendType"-->
                    <!--                                        align="center"-->
                    <!--                                        label="发送人群"-->
                    <!--                                    >-->
                    <!--                                        <template slot-scope="scope">-->
                    <!--                                            <div>-->
                    <!--                                                {{ scope.row.crowd | crodType }}-->
                    <!--                                            </div>-->
                    <!--                                        </template>-->
                    <!--                                    </el-table-column>-->

                    <el-table-column
                        min-width="80"
                        prop="sendNumber"
                        align="center"
                        label="发送人数"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ scope.row | sendNumber }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                        min-width="80"
                        prop="creater"
                        align="center"
                        label="创建人"
                    >
                      <template slot-scope="scope">
                        <div>
                          <span v-if="scope.row.creatorInfo!==null">{{ scope.row.creatorInfo.realName }}</span>
                          <span v-else>--</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="日期"
                        align="center"
                        width="100"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{
                            new Date(
                                scope.row.createTime
                            ).Format(
                                "yyyy-MM-dd hh:mm:ss"
                            )
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="200"
                    >
                      <template slot-scope="scope">
                        <div class="operation-button">
                          <el-switch
                              v-model="scope.row.open"
                              active-color="#13ce66"
                              class="captcha"
                              @click.native="taskIsOpen(scope.row.open,scope.row.id)"
                              disabled
                          >
                          </el-switch>
                          <el-tooltip
                              class="item"
                              effect="dark"
                              content="查看发送记录"
                              placement="top"
                          >
                            <el-button
                                @click="
                                                            handleClick(
                                                                scope.row
                                                            )
                                                        "
                                icon="el-icon-view"
                                size="small"
                                circle
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                              class="item"
                              effect="dark"
                              content="编辑任务"
                              placement="top"
                          >
                            <el-button
                                type="primary"
                                icon="el-icon-edit"
                                size="small"
                                circle
                                @click="
                                                            editTask(scope.row)
                                                        "
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                              class="item"
                              effect="dark"
                              content="删除任务"
                              placement="top"
                          >
                            <el-button
                                type="danger"
                                icon="el-icon-delete"
                                size="small"
                                circle
                                @click="
                                                            deleteTask(
                                                                scope.row.id
                                                            )
                                                        "
                            ></el-button>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination
                      class="task-pagination"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="taskPage"
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="taskPageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="taskTotal"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!--<el-tab-pane  label="素材中心" name="material">
              <material :source="activeTab"></material>
          </el-tab-pane>-->
          <el-tab-pane label="自动化标签" name="autotag">
            <autotag></autotag>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 新增任务抽屉 -->
    <el-drawer
            :title="taskTitle"
            :visible.sync="taskDrawer"
            size="70%"
            direction="rtl"
    >
      <div class="drawer-container">
        <div class="drawer-form-content">
          <el-form
                  :style="{
                                                pointerEvents: notEditTask
                                                    ? 'none'
                                                    : 'auto',
                                            }"
          >
            <el-form-item
                    label="任务名称"
                    label-width="120px"
            >
              <el-input
                      v-model="taskName"
                      autocomplete="off"
                      placeholder="请输入任务名称"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="发送渠道"
                    label-width="120px"
            >
              <div class="select-channel">
                <el-radio-group
                        v-model="channelType"
                        @change="
                                                            channelValueChange
                                                        "
                >
                  <el-radio-button
                          label="WE_WORK"
                  >企业微信
                  </el-radio-button
                  >
                  <!-- <el-radio-button label="DING_TALK">钉钉</el-radio-button>-->
                </el-radio-group>
                <!-- <el-select v-model="selectedChannel" :placeholder="elSelectPlaceholder">
<el-option
v-for="item in channelList"
:key="item.value"
:label="item.label"
:value="item.value">
</el-option>
</el-select>  -->
              </div>
            </el-form-item>

            <!--                                            <el-form-item-->
            <!--                                                label="发送人群"-->
            <!--                                                label-width="120px"-->
            <!--                                            >-->
            <!--                                                <el-radio-group-->
            <!--                                                    v-model="selectedGroupType"-->
            <!--                                                    @change="changeSendPeople"-->
            <!--                                                >-->
            <!--                                                    <el-radio-button-->
            <!--                                                        label="EMPLOYEE"-->
            <!--                                                        >员工</el-radio-button-->
            <!--                                                    >-->
            <!--&lt;!&ndash;                                                    <el-radio-button&ndash;&gt;-->
            <!--&lt;!&ndash;                                                        label="CUSTOMER"&ndash;&gt;-->
            <!--&lt;!&ndash;                                                        >客户</el-radio-button&ndash;&gt;-->
            <!--&lt;!&ndash;                                                    >&ndash;&gt;-->
            <!--&lt;!&ndash;                                                    <el-radio-button&ndash;&gt;-->
            <!--&lt;!&ndash;                                                        label="CUSTOMER_GROUP"&ndash;&gt;-->
            <!--&lt;!&ndash;                                                        >客户群</el-radio-button&ndash;&gt;-->
            <!--&lt;!&ndash;                                                    >&ndash;&gt;-->
            <!--                                                    &lt;!&ndash; <el-radio-button label="CUSTOMER_MOMENT" v-if="channelType == 'WE_WORK' ">客户朋友圈</el-radio-button>&ndash;&gt;-->
            <!--                                                </el-radio-group>-->
            <!--                                            </el-form-item>-->


            <!--                                        sendingAgentList-->
            <el-form-item
                    label="发送应用"
                    label-width="120px">
              <div
                      class="form-item-box">
                <el-select
                        v-model="selectedAgentId"
                        placeholder="请选择"
                        @change="changeSendAgentInfo">
                  <el-option
                          v-for="item in sendingAgentList"
                          :key="item.agentId"
                          :label="item.agentName"
                          :value="item.agentId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
                    label="发送类型"
                    label-width="120px">
              <div
                      class="form-item-box"
                      v-show=" selectedGroupType ==  'EMPLOYEE' ">
                <el-select
                        v-model="sendingRule"
                        placeholder="请选择"
                        @change="changeSendType">
                  <el-option
                          v-for="item in sendingRulesList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="newCustomer" v-show="selectedGroupType==='EMPLOYEE'&&sendingRule==='NEW_EMPLOYEE'">
                <div class="alert-text">
                  需配置【员工变更自动更新】
                </div>
              </div>
              <div
                      class="form-item-box"
                      v-show="
                                                        selectedGroupType == 'CUSTOMER'
                                                         ||selectedGroupType ==  'CUSTOMER_MOMENT' ">
                <el-select
                        v-model=" customerScopeName "
                        placeholder="请选择">
                  <el-option
                          v-for="item in customerScopeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
                </el-select>
                <el-button
                        v-show="
                                                            customerScopeName ==
                                                            'TAG'
                                                        "
                        type="primary"
                        style="
                                                            margin-left: 10px;
                                                        "
                        @click="checkTags"
                >标签选择
                </el-button
                >
              </div>
              <div
                      class="form-item-box"
                      v-show="
                                                        selectedGroupType ==
                                                        'CUSTOMER_GROUP'
                                                    "
              >
                <el-button
                        type="primary"
                        style="
                                                            margin-left: 10px;
                                                        "
                        @click="
                                                            openSelectCustomerQun
                                                        "
                >选择客户群
                </el-button
                >
              </div>
              <div
                      class="
                                                        bind-bot-self-tree-box
                                                    "
                      style="margin-top: 20px"
                      v-show="
                                                        (selectedGroupType ==
                                                            'CUSTOMER' ||
                                                            selectedGroupType ==
                                                                'CUSTOMER_MOMENT') &&
                                                        customerScopeName ===
                                                            'EMPLOYEE'
                                                    "
              >
                <div
                        @click.stop="
                                                            setActiveTreeIndex
                                                        "
                        :class="[
                                                            'selected-box',
                                                        ]"
                >
                  <div class="tag-box">
                                                            <span
                                                                    class="
                                                                    placeholder
                                                                "
                                                                    v-if="
                                                                    employeeConfigTag.length ===
                                                                    0
                                                                "
                                                            >请点击选择发送范围</span
                                                            >
                    <el-tag
                            v-for="(
                                                                    tagCell,
                                                                    tagCellIndex
                                                                ) in employeeConfigTag"
                            :key="
                                                                    tagCellIndex
                                                                "
                            size="mini"
                            @close="
                                                                    handleCloseOrganization(
                                                                        tagCellIndex
                                                                    )
                                                                "
                            closable
                    >{{
                      tagCell.name
                      }}
                    </el-tag
                    >
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item
                    label=" "
                    label-width="120px"
                    v-show="
                                                        (selectedGroupType ===
                                                            'CUSTOMER' ||
                                                            selectedGroupType ===
                                                                'CUSTOMER_MOMENT') &&
                                                        customerScopeName ===
                                                            'TAG'
                                                    "
            >
              <div
                      class="form-tag"
              >
                <el-tag
                        v-for="tag in showTagsList"
                        :key="tag.name"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item
                    label="发送范围"
                    label-width="120px"
                    v-show=" selectedGroupType === 'EMPLOYEE'"
            >
              <div
                      class="
                                                        bind-bot-self-tree-box
                                                    "
              >
                <div
                        @click.stop="
                                                            setActiveTreeIndex
                                                        "
                        :class="[
                                                            'selected-box',
                                                        ]"
                >
                  <div class="tag-box">
                                                            <span
                                                                    class="
                                                                    placeholder
                                                                "
                                                                    v-if="
                                                                    employeeConfigTag.length ===
                                                                    0
                                                                "
                                                            >请点击选择发送范围</span
                                                            >
                    <el-tag
                            v-for="(
                                                                    tagCell,
                                                                    tagCellIndex
                                                                ) in employeeConfigTag"
                            :key="
                                                                    tagCellIndex
                                                                "
                            size="mini"
                            @close="
                                                                    handleCloseOrganization(
                                                                        tagCellIndex
                                                                    )
                                                                "
                            closable
                    >{{
                      tagCell.name
                      }}
                    </el-tag
                    >
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item
                    label-width="120"
                    label=""
                    v-show="
                                                    sendingRule ===
                                                        'SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                                    selectedGroupType ===
                                                        'EMPLOYEE'
                                                "
            >
              <div class="induction-days">
                <span>入职后</span>
                <el-input
                        v-model="
                                                            inductionDaysNumber
                                                        "
                        placeholder="请输入"
                        type="number"
                        min="1"
                        style="width: 100%"
                ></el-input>
                <el-select
                        v-model="
                                                            inductionDaysType
                                                        "
                        placeholder="请选择"
                >
                  <el-option
                          v-for="item in inductionDaysTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span
                        style="
                                                            padding-left: 20px;
                                                        "
                >的员工</span
                >
              </div>
            </el-form-item>
            <el-form-item
                    label="发送时间"
                    label-width="120px"
                    v-show="sendingRule!=='SPECIAL_DATE_FOR_NEW_EMPLOYEE'"
            >
              <div class="send-date one-row">
                <div
                        v-show="
                                                            sendingRule ===
                                                            'GENERAL'
                                                        "
                        class="sendTime"
                >
                  <el-radio-group
                          v-model="
                                                                sendTimeType
                                                            "
                  >
                    <el-radio
                            label="NOW"
                    >立即发送
                    </el-radio
                    >
                    <el-radio
                            label="TIMED"
                    >定时发送
                    </el-radio
                    >
                    <el-radio
                            label="PERIODIC"
                    >周期发送
                    </el-radio
                    >
                  </el-radio-group>
                </div>
                <div
                        v-show="
                                                            sendingRule ===
                                                            'NEW_EMPLOYEE'
                                                        "
                >
                                                        <span
                                                                style="
                                                                color: #366AFF;
                                                            "
                                                        >新员工入职后立即发送</span
                                                        >
                </div>
                <div
                        v-show="
                                                            sendingRule ===
                                                            'BIRTHDAY'
                                                        "
                >
                                                        <span
                                                                style="
                                                                color: #366AFF;
                                                            "
                                                        >过生日当天</span
                                                        >
                </div>
                <!--                            <div
                                                v-show="
                                                                            sendingRule ===
                                                                            'SPECIAL_DATE_FOR_NEW_EMPLOYEE'
                                                                        "
                                            >
                                              <el-radio-group
                                                  v-model="
                                                                                newCustomer
                                                                            "
                                                  @change="
                                                                                specialDate
                                                                            "
                                              >
                                                <el-radio :label="0"
                                                >当天发送
                                                </el-radio
                                                >
                                                <el-radio
                                                    :label="-1"
                                                >之前发送
                                                </el-radio
                                                >
                                                <el-radio :label="1"
                                                >之后发送
                                                </el-radio
                                                >
                                              </el-radio-group>
                                            </div>-->
              </div>
            </el-form-item>
            <el-form-item
                    label=""
                    v-show="
                                                    sendingRule ===
                                                        'SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                                    (newCustomer === -1 ||
                                                        newCustomer === 1)
                                                "
            >
              <div
                      style="
                                                        display: flex;
                                                        justify-content: flex-start;
                                                    "
              >
                                                    <span
                                                            v-show="
                                                            newCustomer === -1
                                                        "
                                                    >之前</span
                                                    >
                <span
                        v-show="
                                                            newCustomer === 1
                                                        "
                >之后</span
                >
                <el-input
                        placeholder="请输入"
                        style="
                                                            width: 30%;
                                                            margin: 0 10px;
                                                        "
                        v-model="specialDay"
                        type="number"
                        min="1"
                ></el-input
                >
                天
              </div>
            </el-form-item>
            <el-form-item
                    label-width="120px"
                    label="周期规则"
                    v-show="
                                                    sendingRule === 'GENERAL' &&
                                                    sendTimeType === 'PERIODIC'
                                                "
            >
              <el-radio-group
                      v-model="periodicRule"
              >
                <el-radio
                        v-for="(
                                                            periodic, index
                                                        ) in periodicRuleList"
                        :label="periodic.value"
                        :key="index"
                >{{
                  periodic.label
                  }}
                </el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
                    label-width="120px"
                    label="选择重复"
                    v-show="
                                                    sendingRule === 'GENERAL' &&
                                                    sendTimeType ===
                                                        'PERIODIC' &&
                                                    (periodicRule === 'WEEK' ||
                                                        periodicRule ===
                                                            'MOUTH')
                                                "
            >
              <el-checkbox-group
                      v-model="setWeek"
                      @change="
                                                        handleCheckedCitiesChange
                                                    "
                      v-if="
                                                        periodicRule === 'WEEK'
                                                    "
              >
                <el-checkbox
                        v-for="(
                                                            week, index
                                                        ) in weekList"
                        :label="week.value"
                        :value="week.value"
                        :key="index"
                >{{
                  week.label
                  }}
                </el-checkbox
                >
              </el-checkbox-group>
              <el-radio-group
                      v-model="setMonthTime"
                      v-if="
                                                        periodicRule === 'MOUTH'
                                                    "
              >
                <el-radio :label="1"
                >每月第一天
                </el-radio
                >
                <el-radio :label="-1"
                >每月最后一天
                </el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
                    label-width="120px"
                    label="选择日期"
                    v-show="
                                                    sendTimeType ===
                                                        'PERIODIC' &&
                                                    periodicRule === 'YEAR' &&
                                                    sendingRule === 'GENERAL'
                                                "
            >
              <el-radio-group
                      v-model="GregorianOrLunar"
                      style="margin-right: 20px"
              >
                <el-radio
                        v-for="(
                                                            data, index
                                                        ) in datePickerList"
                        :label="data.value"
                        :key="index"
                >
                  {{ data.label }}
                </el-radio>
              </el-radio-group>
              <el-date-picker
                      v-model="dataPicker"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="dateOption"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
                    label="选择时间"
                    label-width="120px"
                    v-show="
                                                    sendingRule ===
                                                        'BIRTHDAY' ||
                                                    sendingRule ===
                                                        'SPECIAL_DATE_FOR_NEW_EMPLOYEE' ||
                                                    (sendingRule ===
                                                        'GENERAL' &&
                                                        sendTimeType !== 'NOW')
                                                "
            >
              <div
                      v-if="
                                                        sendingRule !==
                                                            'SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                                        sendingRule !==
                                                            'BIRTHDAY'
                                                    "
              >
                <el-time-picker
                        v-model="checkTime"
                        placeholder="任意时间点"
                        v-if="
                                                            sendTimeType !==
                                                            'TIMED'
                                                        "
                >
                </el-time-picker>
                <el-date-picker
                        v-model="checkDateTime"
                        type="datetime"
                        v-if="
                                                            sendTimeType ===
                                                            'TIMED'
                                                        "
                        :picker-options="
                                                            dateOption
                                                        "
                        @change="dateHandle"
                        placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
              <div
                      v-if="
                                                        sendingRule ===
                                                            'SPECIAL_DATE_FOR_NEW_EMPLOYEE' ||
                                                        sendingRule ===
                                                            'BIRTHDAY'
                                                    "
              >
                <el-time-picker
                        v-model="
                                                            specialDateTime
                                                        "
                        placeholder="任意时间点"
                >
                </el-time-picker>
              </div>
            </el-form-item>
            <el-form-item
                    label="发送内容"
                    label-width="120px"
            >
              <div
                      class="send-content-type"
              ></div>
              <div class="welcome-content">
                <welcome-type
                        :welcome-config="welcomeConfig"
                        :selectedGroupType="selectedGroupType"
                        :selectedAgentId="selectedAgentId"
                        :bot-list="agentBotInfos"
                ></welcome-type>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-preview">
          <dialogue
                  :preview="priview"
                  :welcome-config="welcomeConfig"
          ></dialogue>
        </div>
        <div slot="footer" class="drawer-footer">
          <el-button @click="taskDrawer = false"
          >取 消
          </el-button
          >
          <el-button
                  type="primary"
                  @click="addTask"
                  v-if="!notEditTask"
          >确 定
          </el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 选择素材 -->
    <el-dialog
            title="选择素材"
            :visible.sync="materialDialogVisible"
            :close-on-click-modal="false"
            width="60%"
    >
      <div>
        <Material ref="materialSelect" :source="massSendCheck"></Material>
      </div>
      <span slot="footer" class="dialog-footer">
                                    <el-button
                                            @click="materialDialogVisible = false"
                                    >取 消</el-button
                                    >
                                    <el-button
                                            type="primary"
                                            @click="materialDialogVisible = false"
                                    >确 定</el-button
                                    >
                                </span>
    </el-dialog>
    <!-- 选择客户群 -->
    <el-dialog
            title="选择客户群"
            :visible.sync="SelectCustomerQunDialogVisible"
            :close-on-click-modal="false"
            width="40%"
    >
      <Qun
              :groupChatList="groupChatList"
              @lookMoreQun="lookMoreQun"
              :cursor="cursor"
              ref="selectCustomerQun"
              :notEditTask="notEditTask"
      ></Qun>
      <span slot="footer" class="dialog-footer">
                                    <el-button
                                            @click="
                                            SelectCustomerQunDialogVisible = false
                                        "
                                    >取 消</el-button
                                    >
                                    <el-button
                                            type="primary"
                                            @click="SelectCustomerQun"
                                    >确 定</el-button
                                    >
                                </span>
    </el-dialog>
    <!-- 客户标签 -->
    <el-dialog
            title="选择客户标签"
            :close-on-click-modal="false"
            :visible.sync="cutomerTagDialogVisible"
            width="50%"
    >
      <Tag ref="tagSelection"></Tag>
      <span slot="footer" class="dialog-footer">
                                    <el-button @click="cacelTags"
                                    >取 消</el-button
                                    >
                                    <el-button
                                            type="primary"
                                            @click="selectTags"
                                    >确 定</el-button
                                    >
                                </span>
    </el-dialog>

    <!--选择成员-->
    <el-dialog
            title="选择成员"
            :visible.sync="memberDialogTableVisible"
            :close-on-click-modal="false"
            width="500px"
            class="selectMember"
    >
      <div class="bind-bot-self-tree">

        <DepartmentTree :data="memberList" @onNodeClick="onNodeClick">
        </DepartmentTree>
        <!--             TODO:       -->

        <!--  <DepartmentPanel :data="[]">

          </DepartmentPanel>-->

        <!--                    <el-tree-->
        <!--                        :data="memberList"-->
        <!--                        node-key="id"-->
        <!--                        :default-expand-all="defaultExpandAll"-->
        <!--                        :props="defaultProps"-->
        <!--                        :expand-on-click-node="false"-->
        <!--                    >-->
        <!--                                        <span-->
        <!--                                            class="custom-tree-node"-->
        <!--                                            slot-scope="{ node, data }"-->
        <!--                                        >-->
        <!--                                            <div style="display: flex;justify-content: flex-start;align-items: center">-->
        <!--                                                <span v-if="!data.isMember">-->
        <!--                                                    <i class="arsenal_icon arsenalsitemap1"-->
        <!--                                                       style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>-->
        <!--                                                </span>-->
        <!--                                                <span v-if="data.isMember">-->
        <!--                                                    <i class="arsenal_icon arsenaluser1"-->
        <!--                                                       style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>-->
        <!--                                                </span>-->
        <!--                                                <span>{{ node.label }}</span>-->
        <!--                                            </div>-->

        <!--                                            <span>-->
        <!--                                                <el-checkbox-->
        <!--                                                    @change="-->
        <!--                                                        changeCheckedOrg(-->
        <!--                                                            node,-->
        <!--                                                            data-->
        <!--                                                        )-->
        <!--                                                    "-->
        <!--                                                    v-model="data.checked"-->
        <!--                                                ></el-checkbox>-->
        <!--                                            </span>-->
        <!--                                        </span>-->
        <!--                    </el-tree>-->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
                @click="
                                            memberDialogTableVisible = false
                                        "
        >取 消
        </el-button
        >
        <el-button
                type="primary"
                @click="confirmMember"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    <!--查看发送记录-->
    <el-drawer
            :title="sendRecordTitle"
            :visible.sync="sendRecord"
            direction="rtl"
            size="600px"
    >
      <sending-records :sendRecordList="newSendRecordList" :taskRow="taskRow" @closeDetail="sendRecord = false" ref="sendRecord"></sending-records>
      <pagination
          :pageSize="taskRecord.size"
          :currentPage="taskRecord.page"
          :total="recordTotal"
          @handleSizeChange="recordPageSizeChange"
          @currentChange="recordPageChange">
      </pagination>
      <!-- 旧版发送记录 -->
      <el-table
              :data="sendRecordList"
              height="calc(100vh - 120px)"
              v-if="false"
      >
        <el-table-column
                type="index"
                label="序号"
                width="120"
                align="center"
        >
          <template slot-scope="scope">
            <span>{{ sendRecordList.length - scope.$index }}</span>
          </template>
        </el-table-column>
        <el-table-column
                property="name"
                label="接收人"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.displayInfo">
              <open-data v-if="scope.row.displayInfo.wechatThirdRender" :type="scope.row.displayInfo.type==='MEMBER'?'userName':'departmentName'" :openid="scope.row.displayInfo.name"></open-data>
              <span v-else>{{scope.row.displayInfo.name}}</span>
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column
                label="发送时间"
                width="200"
                align="center"
        >
          <template slot-scope="scope">
            <div>{{ new Date(scope.row.createTime).Format('yyyy-MM-dd hh:mm:ss') }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>

import Material from "./components/materialSelection";
import Tag from "./components/tagSelection";
import Qun from "./components/qunSelection";
import WelcomeType from "./components/welcomeType";
import Dialogue from "./components/dialogue";
import {formatTree} from "./components/formatTree";
import Calendar from "./components/Calendar";
import Autotag from "./components/AutoTag";
import {showParent} from "@/views/fissionMarketing/components/getChildrenLeng";
import DepartmentTree from "./components/DepartmentTree";
import DepartmentPanel from "./components/DepartmentSelectedPanel";
import TopHeader from "./components/top-header";
import MessSendTable from "./messSendTable";
import TaskContent from "./components/taskContent";
import OpenData from "@/components/openData";
import pagination from "@/views/fissionMarketing/components/pagination";
import SendingRecords from "@/views/fissionMarketing/components/sendingRecords";
export default {
  components: {
    SendingRecords,
    OpenData,
    TaskContent,
    MessSendTable,
    TopHeader, DepartmentPanel, DepartmentTree, Calendar, Dialogue, WelcomeType, Material, Tag, Qun, Autotag,pagination},
  data() {
    const generateData = (_) => {
      const data = [];
      const cities = [];
      const pinyin = [];
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          pinyin: pinyin[index],
        });
      });
      return data;
    };
    return {
      tabPosition: 'left',
      tableData: [],
      switchValue: true,
      taskDrawer: false,
      deleteTaskDialogVisible: false,
      memberDialogTableVisible: false,
      taskName: "",
      channelType: "WE_WORK",
      elSelectPlaceholder: "请选择应用类型",
      isSelectedChannelDisable: true,
      channelList: [
        {
          value: "0",
          label: "企微-果然SCRM",
        },
        {
          value: "1",
          label: "公众号-客户管理",
        },
      ],
      selectedChannel: "",
      selectedGroupType: "EMPLOYEE",
      selectedAgentId: "",
      selectedSecret: '',
      selectedAgentInfo: {},
      sendingAgentList: [],//发送消息的应用列表
      agentId2AgentInfo: {},
      isGroupTypeDisable: true,
      sendingRulesList: [
        {
          value: "GENERAL",
          label: "通用消息",
        },
        {
          value: "NEW_EMPLOYEE",
          label: "新员工入职",
        },
        {
          value: "BIRTHDAY",
          label: "员工生日祝福",
        },
        {
          value: "SPECIAL_DATE_FOR_NEW_EMPLOYEE",
          label: "入职特殊日期",
        },
      ],
      sendingRule: "GENERAL",
      customerScopeList: [
        {
          value: "EMPLOYEE",
          label: "按员工选择",
        },
        {
          value: "TAG",
          label: "按标签选择",
        },
        {
          value: "ALL",
          label: "全部客户",
        },
      ],
      customerScopeName: "ALL",
      inductionDaysNumber: 1,
      inductionDaysTypeList: [
        {
          value: "DAY",
          label: "天",
        },
        {
          value: "WEEK",
          label: "周",
        },
        {
          value: "MONTH",
          label: "月",
        },
        {
          value: "YEAR",
          label: "年",
        },
      ],
      inductionDaysType: "DAY",
      sendDateTypeList: [
        {
          value: "0",
          label: "指定日期",
        },
        {
          value: "1",
          label: "立即发送",
        },
      ],
      sendDateType: "",
      SendContentTextarea: "",
      form: {
        props: {multiple: true},
        options: [],
      },
      materialDialogVisible: false,
      SelectCustomerQunDialogVisible: false,
      chatbotTypeTitle: "",
      chatbotTypeDesc: "",
      miniProgramTitle: "",
      miniProgramAppID: "",
      miniProgramPage: "",
      addChatbotDialogVisible: false,
      addMiniProgramDialogVisible: false,
      // 客户标签
      cutomerTagDialogVisible: false,
      // 穿梭框
      data: generateData(),
      value: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      welcomeConfig: {
        attachments: [],
        text: {
          content: "",
        },
      },
      priview: "群发消息预览",
      fileList: [],
      sendTimeType: "NOW",
      checkTime: "",
      specialDateTime: "",
      checkDateTime: "",
      sendDays: "1",
      newCustomer: 0,
      showTagsList: [],
      memberList: [],
      orgTree: [],
      defaultExpandAll: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      userIds: [],
      keyValue: 0,
      activeTreeIndex: 0,
      userTags: [],
      showTree: false,
      periodicRuleList: [
        {
          value: "DAY",
          label: "每天",
        },
        {
          value: "WEEK",
          label: "每周",
        },
        {
          value: "MOUTH",
          label: "每月",
        },
        {
          value: "YEAR",
          label: "每年",
        },
      ], //周期规则
      periodicRule: "DAY",
      weekList: [
        {
          value: 2,
          label: "周一",
        },
        {
          value: 3,
          label: "周二",
        },
        {
          value: 4,
          label: "周三",
        },
        {
          value: 5,
          label: "周四",
        },
        {
          value: 6,
          label: "周五",
        },
        {
          value: 7,
          label: "周六",
        },
        {
          value: 1,
          label: "周日",
        },
      ],
      setWeek: [2],
      setMonthTime: 1,
      dataPicker: "",
      dateOption: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        },
      },
      datePickerList: [
        {
          value: "CALENDAR",
          label: "公历",
        },
        {
          value: "LUNAR",
          label: "农历",
        },
      ],
      GregorianOrLunar: "CALENDAR",
      treeExpandData: [],
      taskPage: 1,
      taskPageSize: 10,
      taskTotal: 0,
      tagsList: [],
      selectTask: {},
      taskTitle: "新增群发任务",
      testUserIds: [],
      taskId: "",
      notEditTask: false,
      groupChatList: [],
      cursor: "",
      groupIds: [],
      sendRecord: false,
      sendRecordList: [],
      specialDay: 1,
      activeTab: 'massSend',
      currentPath: '',
      massSendCheck: 'massSend',
      agentBotInfos: [],
      employeeConfigUserIds: [],
      employeeConfigTag: [],
      taskCropId: '',
      leftSide:[
        {
          name:'群发任务',
          link: "massSend",
          icon:'guoran-qunfarenwu',
          isActive:true
        },
        {
          name:'自动化流程',
          link: "autotag",
          icon:'guoran-a-14-12',
          isActive:false
        }
      ],
      taskRecord:{
        page:1,
        size:8,
      },
      recordTotal:0,
      taskRow:{},
      sendRecordTitle:"",
      newSendRecordList:[],
      timers:null
    };
  },

  methods: {
    checkSideBar(item){
      this.leftSide.forEach(items=>{
        this.$set(items,'isActive',false)
      });
      this.$set(item,'isActive',true);
      this.activeTab = item.link;
    },
    tabClick(value) {

    },
    onNodeClick(node) {
      console.debug('onNodeClick', node);
      if (node.checked){
        this.userTags.push(node)
      }
      else {
       for (let i=0;i<this.userTags.length;i++){
         if (this.userTags[i].id===node.id){
           this.userTags.splice(i,1)
         }
       }
      }
      this.userTags=[...this.userTags]
      console.log(this.userTags)
    },
    confirmMember(){
      this.employeeConfigTag = []
      this.employeeConfigTag = this.userTags
      this.memberDialogTableVisible=false
    },
    getCurrentUrl() {
      this.currentPath = this.$route.path;
      console.log('path:', this.currentPath);
    },
    handleClick(value) {
      this.sendRecord = true;
      this.newSendRecordList = [];
      this.taskRow = value;
      this.taskRecord.page = 1;
      this.sendRecordTitle = '发送任务 - ' + value.name;
      console.debug('value',value)
      this.getNewTaskRecord(value)
    },
    getTaskRecord(value){
      /*发送内容详情及是否成功文档地址：https://u9zkf6yo6j.feishu.cn/docx/doxcnndataS7maaeCqni0i5fdre*/
      this.taskRow = value;
      const params = Object.assign({}, this.taskRecord)
      params.page = params.page - 1
      let url = '/scrm-api/group-send-message-task-record/detail/' + value.id +'?page=' +  params.page + '&size=' + params.size
      this.FetchGet(url).then(res => {
        console.log('发送记录', res)
        this.sendRecordList = res.content;
        this.recordTotal = res.totalElements
      })
    },
    getNewTaskRecord(value){
      const params = Object.assign({}, this.taskRecord)
      params.page = params.page - 1
      let url = "/scrm-api/group-send-message-task-record/attachment-detail/" + value.id +'?page=' +  params.page + '&size=' + params.size;
      this.FetchGet(url).then(res=>{
        console.debug('发送记录',res)
        this.newSendRecordList = res.content;
        this.recordTotal = res.totalElements;
        this.$nextTick(() => {
          for (let i=0;i<this.newSendRecordList.length;i++){
            let item = this.newSendRecordList[i];
            let record = this.$refs.sendRecord.$refs[item.id][0];
            record.personnelList = [];
            record.failPersonnelList = [];
            record.getPlayInfo();
            record.getFailInfo();
          }
        })
      })
    },
    taskIsOpen(value, id) {
      console.log(value)
      let url = ''
      if (!value) {
        this.$confirm('是否开启任务?', '开启任务', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          url = '/scrm-api/group-send-message-task/open/'
          this.FetchPut(url, id).then(res => {
            console.log(res)
            this.$message({
              message: '任务打开',
              duration: 2000,
              type: 'success'
            })
            this.getTask()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消开启任务'
          });
        });

      } else {
        this.$confirm('是否关闭任务?', '关闭任务', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          url = '/scrm-api/group-send-message-task/close/'
          this.FetchPut(url, id).then(res => {
            console.log(res)
            this.$message({
              message: '任务关闭',
              duration: 2000,
              type: 'success'
            })
            this.getTask()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消关闭任务'
          });
        });

      }
    },
    editTask(value) {
      console.log("123Value", value);
      this.taskName = value.name;
      this.channelType = value.channel;
      this.selectedGroupType = value.crowd;
      this.welcomeConfig.attachments = JSON.parse(JSON.stringify(value.attachments));

      this.welcomeConfig.text = JSON.parse(JSON.stringify(value.text));
      this.testUserIds = [];
      this.userTags = [];
      this.employeeConfigTag =[]
      this.sendTimeType = "NOW";
      this.periodicRule = "DAY";
      this.checkTime = "";
      this.specialDateTime = "";
      this.newCustomer = 0;
      this.inductionDaysNumber = 1;
      this.checkDateTime = "";
      this.specialDay = 1;
      this.inductionDaysType = "DAY";
      this.setWeek = [2];
      this.showTagsList = [];
      this.groupIds = [];

      this.taskTitle = "修改群发任务";
      this.selectTask = value;
      this.taskDrawer = true;
      this.taskId = value.id;
      this.notEditTask = false;
      this.showTree = false;
      this.selectedAgentId = "";
      this.selectedSecret = ''
      this.taskCropId = ''
      this.initDefault(value.crowd, this.selectTask);


    },
    //切换发送类型初始化数据
    initDefault(type, value) {
      if (type === "EMPLOYEE") {

        if (value.weWorkEmployeeConfig !== null) {
          this.taskCropId = value.weWorkEmployeeConfig.cropId
          if (value.weWorkEmployeeConfig.sendTimeType !== null) {
            this.sendTimeType =
                value.weWorkEmployeeConfig.sendTimeType;
          }
          if (value.weWorkEmployeeConfig.weWorkAgentId !== null) {
            this.selectedAgentId = value.weWorkEmployeeConfig.weWorkAgentId
            if (value.weWorkEmployeeConfig.originSelectIds) {
              this.loadMemberInfo(this.agentId2AgentInfo[this.selectedAgentId].corpId, this.agentId2AgentInfo[this.selectedAgentId].agentId, this.agentId2AgentInfo[this.selectedAgentId].secret, value.weWorkEmployeeConfig.originSelectIds);
              this.loadBotInfo(this.agentId2AgentInfo[this.selectedAgentId].agentId, this.agentId2AgentInfo[this.selectedAgentId].corpId);
            }
          }
          if (value.weWorkEmployeeConfig.weWorkAgentSecret !== null) {
            this.selectedSecret = value.weWorkEmployeeConfig.weWorkAgentSecret
          }
          this.sendingRule = value.sendType;
          if (this.sendTimeType === "TIMED") {
            if (value.weWorkEmployeeConfig.timed.date !== null) {
              this.checkDateTime =
                  value.weWorkEmployeeConfig.timed.date;
            }
          }
          if (value.sendType === "BIRTHDAY") {
            if (
                value.weWorkEmployeeConfig.birthDayDateConfig !==
                null
            ) {
              this.specialDateTime =
                  value.weWorkEmployeeConfig.birthDayDateConfig.time;
            }
          }
          console.log("value.sendType", value);
          if (value.sendType === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
            if (
                value.weWorkEmployeeConfig
                    .newEmployeeSpecialDateConfig !== null
            ) {
              if (
                  value.weWorkEmployeeConfig
                      .newEmployeeSpecialDateConfig.diff !== null
              ) {
                if (
                    value.weWorkEmployeeConfig
                        .newEmployeeSpecialDateConfig.diff < 0
                ) {
                  this.newCustomer = -1;
                  this.specialDay =
                      -value.weWorkEmployeeConfig
                          .newEmployeeSpecialDateConfig.diff;
                } else if (
                    value.weWorkEmployeeConfig
                        .newEmployeeSpecialDateConfig.diff > 0
                ) {
                  this.newCustomer = 1;
                  this.specialDay =
                      value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.diff;
                } else {
                  this.newCustomer = 0;
                  this.specialDay = 1;
                }
              }
              this.inductionDaysNumber =
                  value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.duration.num;
              this.inductionDaysType =
                  value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.duration.timeUnit;
              this.specialDateTime =
                  value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.time;
            }
          }
          if (value.weWorkEmployeeConfig.periodic !== null) {
            this.periodicRule =
                value.weWorkEmployeeConfig.periodic.rule;
            if (
                value.weWorkEmployeeConfig.periodic.weekConfig !==
                null
            ) {
              this.setWeek =
                  value.weWorkEmployeeConfig.periodic.weekConfig.days;
              this.checkTime =
                  value.weWorkEmployeeConfig.periodic.weekConfig.time;
            }
            if (
                value.weWorkEmployeeConfig.periodic.dayConfig !==
                null
            ) {
              this.checkTime =
                  value.weWorkEmployeeConfig.periodic.dayConfig.time;
            }
            if (
                value.weWorkEmployeeConfig.periodic.monthConfig !==
                null
            ) {
              this.checkTime =
                  value.weWorkEmployeeConfig.periodic.monthConfig.time;
              this.setMonthTime =
                  value.weWorkEmployeeConfig.periodic.monthConfig.day;
            }
            if (
                value.weWorkEmployeeConfig.periodic.yearConfig !==
                null
            ) {
              this.checkTime =
                  value.weWorkEmployeeConfig.periodic.yearConfig.time;
              this.dataPicker =
                  value.weWorkEmployeeConfig.periodic.yearConfig.date;
              this.GregorianOrLunar =
                  value.weWorkEmployeeConfig.periodic.yearConfig.type;
            }
          }
        }
      }
    },
    /*遍历人员信息*/
    seachSelectUserIds(value, ids) {
      if (value !== null) {
        for (let i = 0; i < value.length; i++) {
          if (ids.indexOf(value[i].id) !== -1) {
            this.employeeConfigTag.push(value[i]);
            this.seachSelectUserIds(value[i].children, ids);
          } else {
            this.seachSelectUserIds(value[i].children, ids);
          }
        }
      } else return;

    },

    channelValueChange() {
      this.isSelectedChannelDisable = false;
      if (this.channelType == "WE_WORK") {
        this.elSelectPlaceholder = "请选择企业微信应用";
      } else if (this.channelType == "DING_TALK") {
        this.elSelectPlaceholder = "请选择钉钉应用";
      }
    },
    changeSelectedChannel() {
      this.isGroupTypeDisable = false;
    },
    handleRemove() {
    },
    handlePreview() {
    },
    changeSendAgentInfo(agentId) {
      console.log(agentId)
      console.log(this.agentId2AgentInfo[agentId])
      this.selectedAgentId = this.agentId2AgentInfo[agentId].agentId
      this.selectedAgentInfo = this.agentId2AgentInfo[agentId];
      this.selectedSecret = this.agentId2AgentInfo[agentId].secret;
      console.debug("agent info changed", this.selectedAgentInfo);
      this.loadMemberInfo(this.agentId2AgentInfo[agentId].corpId, this.agentId2AgentInfo[agentId].agentId, this.agentId2AgentInfo[agentId].secret);
      this.loadBotInfo(this.agentId2AgentInfo[agentId].agentId, this.agentId2AgentInfo[agentId].corpId);

    },
    loadBotInfo(agentId, corpId) {
      this.agentBotInfos = [];
      let url = "/scrm-api/intermediate/bot-infos-agent?agentId=" + agentId + "&corpId=" + corpId;
      this.FetchGet(url).then((res) => {
        console.debug('load agent bot infos', res);
        this.agentBotInfos = res;
      })
    },
    loadMemberInfo(corpId, agentId, secret, userIds) {
      this.memberList = [];
      let url = "/scrm-api/we-work/member-agent?agentId=" + agentId + '&secret=' + secret + "&corpId=" + corpId;
      this.FetchGet(url).then((res) => {
        console.log('人员信息', res);
        if (res !== '') {
          this.memberList = res;
          this.memberList.forEach((item) => {
            this.$set(item, "checked", false);
            this.setChecked(item.children);
          });
          /*if (this.memberList.length > 0) {
            this.getRoleTreeRootNode(this.memberList[0].id);
          }*/

          console.log(this.memberList);
        }
        if (userIds) {
          this.seachSelectUserIds(
              this.memberList,
              userIds
          );
          let obj = {};
          let peon = this.employeeConfigTag.reduce((cur, next) => {
            obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
            return cur;
          }, [])
          this.employeeConfigTag = peon
          this.userTags=peon
          console.log('selectedAgentIdselectedAgentId', this.employeeConfigTag)
        }

      });
    },
    addTaskClick() {
      this.$router.push({path:'taskContent'})
     /* this.notEditTask = false;
      this.taskTitle = "新增群发任务";
      this.taskDrawer = true;
      this.welcomeConfig.attachments = [];
      this.channelType = "WE_WORK";
      this.selectedGroupType = "EMPLOYEE";
      this.taskName = "";
      this.sendingRule = "GENERAL";
      this.welcomeConfig.text = {
        content: "",
      };
      this.userTags = [];
      this.employeeConfigTag = []
      this.customerScopeName = "ALL";
      this.groupIds = [];
      this.showTagsList = [];
      this.setWeek = [2];
      this.checkTime = "";
      this.specialDateTim = "";
      this.checkDateTime = "";
      this.sendTimeType = "NOW";
      this.periodicRule = "DAY";
      this.testUserIds = [];
      this.selectedAgentId = "";
      this.selectedSecret = ''
      this.taskCorpId = ''*/
    },
    addRg(){
      this.$refs.autoTag.addRg()
    },
    loadAgentList() {
      //agentId2AgentInfo
      this.FetchGet("/portal-api/we-work/agent-info").then((res) => {
        console.log('发送的应用', res)
        this.sendingAgentList = res.filter((agent) => agent.wechatType === 0);
        if (this.sendingAgentList && this.sendingAgentList.length > 0) {
          for (let i = 0, len = this.sendingAgentList.length; i < len; i++) {
            let agentInfo = this.sendingAgentList[i];
            this.agentId2AgentInfo[agentInfo.agentId] = agentInfo;
          }
        }
      });
    },
    dateHandle() {
      if (this.checkDateTime) {
        var startAt = (new Date(this.checkDateTime) * 1000) / 1000;
        if (startAt < Date.now()) {
          this.checkDateTime = new Date();
        }
      }

    },
    TimeHandle() {
      if (this.checkTime) {
        var startAt = (new Date(this.checkTime) * 1000) / 1000;
        if (startAt < Date.now()) {
          this.checkTime = new Date();
        }
      }

    },
    //切换发送类型
    changeSendType(value) {
      console.log(value);

    },
    //新增任务
    addTask() {
      let originSelectIds = [];
      let employeeSelectedConfigs = []
      let tags = [];
      let taskInfo = {
        attachments: this.welcomeConfig.attachments,
        channel: this.channelType,
        crowd: this.selectedGroupType,
        name: this.taskName,
        sendType: "",
        text: this.welcomeConfig.text,
        weWorkEmployeeConfig: {}
      };
      let timed = {};
      if (this.sendTimeType == "TIMED") {
        timed.date = this.checkDateTime;
      }
      let type = "";
      if (this.selectedGroupType === "EMPLOYEE") {
        taskInfo.sendType = this.sendingRule;
        originSelectIds = this.employeeConfigTag.length >0?this.employeeConfigTag.map(value=>value.id):[]
        employeeSelectedConfigs = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => {
          let info={
            type:value.isMember?'MEMBER':'DEPARTMENT',
            id:value.id
          }
          return info
        }) : []
        taskInfo.weWorkEmployeeConfig = {
          sendType: this.sendingRule,
          weWorkAgentId: this.selectedAgentId,
          weWorkAgentSecret: this.selectedSecret,
          cropId: this.selectedAgentInfo.corpId,
          agentName: this.selectedAgentInfo.agentName,
          originSelectIds: originSelectIds,
          employeeSelectedConfigs:employeeSelectedConfigs,
          version: 1,
          sendTimeType: this.sendTimeType,
          timed: timed,
          periodic: {},
        };
        if (this.sendingRule === "BIRTHDAY") {
          taskInfo.weWorkEmployeeConfig.birthDayDateConfig = {
            time: this.specialDateTime,
          };
        }
        if (this.sendingRule === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
          console.log(this.newCustomer * this.specialDay);
          taskInfo.weWorkEmployeeConfig.newEmployeeSpecialDateConfig =
              {
                diff: this.newCustomer * this.specialDay,
                duration: {
                  num: Number(this.inductionDaysNumber),
                  timeUnit: this.inductionDaysType.trim(),
                },
                time: this.specialDateTime,
              };
        }
        if (this.sendTimeType === "PERIODIC") {
          taskInfo.weWorkEmployeeConfig.periodic.rule =
              this.periodicRule;
          if (this.periodicRule === "DAY") {
            taskInfo.weWorkEmployeeConfig.periodic.dayConfig = {
              time: this.checkTime,
            };
          }
          if (this.periodicRule === "WEEK") {
            taskInfo.weWorkEmployeeConfig.periodic.weekConfig = {
              days: this.setWeek,
              time: this.checkTime,
            };
          }
          if (this.periodicRule === "MOUTH") {
            taskInfo.weWorkEmployeeConfig.periodic.monthConfig = {
              day: this.setMonthTime,
              time: this.checkTime,
            };
          }
          if (this.periodicRule === "YEAR") {
            taskInfo.weWorkEmployeeConfig.periodic.yearConfig = {
              date: this.dataPicker,
              time: this.checkTime,
              type: this.GregorianOrLunar,
            };
          }
        }
      }
      console.log(taskInfo);
      if (this.taskName === "") {
        this.$message({
          message: "任务名称不能为空",
          duration: 2000,
          type: "warning",
        });
        return;
      }
      if (this.selectedAgentId === '') {
        this.$message({
          message: "请选择发送应用",
          duration: 2000,
          type: "warning",
        });
        return;
      }
      if (
          ((this.selectedGroupType === "CUSTOMER" &&
              this.customerScopeName === "EMPLOYEE") ||
              (this.selectedGroupType === "EMPLOYEE" &&
                  this.sendingRule !== "NEW_EMPLOYEE")) &&
          this.employeeConfigTag.length === 0
      ) {
        this.$message({
          message: "请选择发送范围",
          duration: 2000,
          type: "warning",
        });
        return;
      }
      if (
          this.selectedGroupType === "CUSTOMER" &&
          this.customerScopeName === "TAG" &&
          tags.length === 0
      ) {
        this.$message({
          message: "请选择标签",
          duration: 2000,
          type: "warning",
        });
        return;
      }
      if (this.sendTimeType === "TIMED") {
        let time = true;
        if (this.checkDateTime === "") {
          this.$message({
            message: "请选择时间",
            duration: 2000,
            type: "warning",
          });
          return (time = false);
        }
        if (!time) {
          return;
        }
      }
      if (this.sendTimeType === "PERIODIC") {
        let time = true;
        if (this.periodicRule === "WEEK") {
          if (this.setWeek.length === 0) {
            this.$message({
              message: "请选择重复时间",
              duration: 2000,
              type: "warning",
            });
            return (time = false);
          }
        }
        if (this.periodicRule === "YEAR") {
          if (this.dataPicker === "") {
            this.$message({
              message: "请选择日期",
              duration: 2000,
              type: "warning",
            });
            return (time = false);
          }
        }
        if (this.checkTime === "") {
          this.$message({
            message: "请选择时间",
            duration: 2000,
            type: "warning",
          });
          return (time = false);
        }
        if (!time) {
          return;
        }
      }
      if (taskInfo.text.content === '' && taskInfo.attachments.length === 0) {
        this.$message({
          message: "请至少有一项发送内容",
          duration: 2000,
          type: "warning",
        });
        return
      }
      if (this.taskTitle === "新增群发任务") {
        let url = "/scrm-api/group-send-message-task";

        this.FetchPost(url, taskInfo).then((res) => {
          console.log(res);
          this.$message({
            message: "新增任务成功",
            duration: 2000,
            type: "success",
          });
          this.getTask();
        });
      } else {
        let url = "/scrm-api/group-send-message-task/";
        taskInfo.weWorkEmployeeConfig.cropId = this.taskCropId
        console.log(taskInfo)
        this.FetchPut(url, this.taskId, taskInfo).then((res) => {
          this.$message({
            message: "更新任务成功",
            duration: 2000,
            type: "success",
          });
          this.getTask();
        });
      }
      this.taskDrawer = false;
      this.showTree = false;
    },
    //删除任务
    deleteTask(id) {
      console.log(id);
      this.taskId = id;

      this.$confirm("确定删除任务, 是否继续?", "删除任务", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let url = "/scrm-api/group-send-message-task/" + id;
            this.FetchDelete2(url).then((res) => {
              this.$message({
                message: "删除任务成功",
                duration: 2000,
                type: "success",
              });
              this.getTask();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //获取群发任务
    getTask() {
      let data = Object.assign({}, this.taskPage, {
        page: this.taskPage - 1,
      });
      let url =
          "/scrm-api/group-send-message-task/page?page=" +
          data.page +
          "&size=" +
          this.taskPageSize;
      this.FetchGet(url).then((res) => {
        console.log(res);
        this.tableData = res.content;
        console.log('我是数据', this.tableData)
        this.taskTotal = res.totalElements;
      });
    },
    checkTags() {
      this.cutomerTagDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.tagSelection.tags = this.showTagsList;
        let ids = [];
        for (let i = 0; i < this.showTagsList.length; i++) {
          ids.push(this.showTagsList[i].id);
        }
        this.$refs.tagSelection.$refs.tree.setCheckedKeys(ids);
      });
    },
    //选择标签
    selectTags() {
      this.$nextTick(() => {
        this.showTagsList = this.$refs.tagSelection.tags;
        this.cutomerTagDialogVisible = false;
      });
    },
    //取消选择标签
    cacelTags() {
      this.cutomerTagDialogVisible = false;
      /*this.showTagsList=this.$refs.tagSelection.tags*/
    },
    loadTagsData() {
      this.FetchGet("/scrm-api/client-tag/group").then((response) => {
        console.log("tags list:", response);
        this.tagsList = formatTree(response);
      });
    },
    handleTagClose(id) {
      console.log(id);
      this.$nextTick(() => {
        console.log(this.$refs.tagSelection);
        if (this.$refs.tagSelection !== undefined) {
          this.$refs.tagSelection.deleteTag(id);
          this.showTagsList = this.$refs.tagSelection.tags;
        } else {
          this.$message.warning("请先打开选择标签再删除");
        }
      });
    },
    //获取员工
    getWeWorkList() {
      let url = "/scrm-api/we-work/member";
      this.FetchGet(url).then((res) => {
        console.log(res);
        this.memberList = res;
        this.memberList.forEach((item) => {
          this.$set(item, "checked", false);
          this.setChecked(item.children);
        });
        this.getRoleTreeRootNode(this.memberList[0].id);
        console.log(this.memberList);
      });
    },
    // 获取树形结构默认展开节点
    getRoleTreeRootNode(provincialCenterId) {
      this.treeExpandData.push(provincialCenterId);
    },
    setChecked(value) {
      if (value !== null) {
        value.forEach((item) => {
          this.$set(item, "checked", false);
          this.setChecked(item.children);
        });
      } else return value;
    },
    setActiveTreeIndex() {
      console.log("123");
      /*  this.showTree=!this.showTree*/
      if (!this.selectedAgentId) {
        this.$message({
          message: '请先选择发送应用',
          type: 'warning',
          duration: 2000
        })
        return
      }
      this.memberDialogTableVisible = true;
      let arr = [];
      this.employeeConfigTag.forEach((item) => {
        arr.push(item.id);
      });
      console.log("arrarr", arr);
      setTimeout(() => {
        this.initShowOrTree(arr, true, "init");
       /* setTimeout(() => {
          showParent(this.userTags, 0)
        }, 100)*/
      }, 100);
    },
    selectOrg(data, checked, indeterminate) {
      /* let treeId = "tree" + this.activeTreeIndex;
      this.bindBots[this.activeTreeIndex].items = this.$refs[
          treeId
          ][0].getCheckedNodes();*/
      console.log("12345");
    },
    changeOrgChildrenProperties(list, fun) {
      if (list == null || list.length == 0 || fun == null) {
        return;
      }
      list.forEach(item => {
        fun(item);
        this.changeOrgChildrenProperties(item.children, fun);
      });

    },
    changeCheckedOrg(node, data) {
      console.log(data);
      this.changeOrgChildrenProperties(data.children, (item) => item.checked = data.checked);
      if (data.checked) {
        this.changeOrgChildrenProperties([data], (item) => this.userTags.unshift(item));
      } else {
        this.changeOrgChildrenProperties([data], (item) => {
          this.userTags.forEach((cell, cellIndex) => {
            if (cell.id == item.id) {
              this.userTags.splice(cellIndex, 1);
            }
          });
        });
      }
      this.userTags = [...this.userTags];
      showParent(this.userTags, 0)
      console.log("userTags", this.userTags);
    },
    handleCloseOrganization(tagCellIndex) {
      this.initShowOrTree(
          [this.employeeConfigTag[tagCellIndex].id],
          false,
          "close"
      );
      this.employeeConfigTag.splice(tagCellIndex, 1);
    },
    initShowOrTree(handleIdArr, isChecked, type) {
      const formOrgTree = (arr, handleIdArr, isChecked) => {
        if (type == "close") {
          arr.forEach((item) => {
            if (handleIdArr.indexOf(item.id) != -1) {
              item.checked = isChecked;
              if (item.children) {
                formOrgTree(
                    item.children,
                    handleIdArr,
                    isChecked
                );
              }
            } else {
              if (item.children) {
                formOrgTree(
                    item.children,
                    handleIdArr,
                    isChecked
                );
              }
            }
          });
        } else {
          arr.forEach((item) => {
            if (handleIdArr.indexOf(item.id) != -1) {
              item.checked = isChecked;
              if (item.children) {
                formOrgTree(
                    item.children,
                    handleIdArr,
                    isChecked
                );
              }
            } else {
              item.checked = !isChecked;
              if (item.children) {
                formOrgTree(
                    item.children,
                    handleIdArr,
                    isChecked
                );
              }
            }
          });
        }

        return arr;
      };
      this.memberList = [
        ...formOrgTree(this.memberList, handleIdArr, isChecked),
      ];
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
    },
    /*切换发送人群，数据刷新*/
    changeSendPeople(value) {
      this.showTree = false;
      this.selectedGroupType = value;
      this.showTagsList = [];
      this.userTags = [];
      this.employeeConfigTag = []
      this.welcomeConfig.attachments = [];
      this.channelType = "WE_WORK";
      this.sendingRule = "GENERAL";
      this.welcomeConfig.text = {
        content: "",
      };
      this.customerScopeName = "ALL";
      this.groupIds = [];
      this.setWeek = [2];
      this.checkTime = "";
      this.checkDateTime = "";
      this.sendTimeType = "NOW";
      this.periodicRule = "DAY";
      this.testUserIds = [];
      if (this.taskTitle === "修改群发任务") {
        this.initDefault(value, this.selectTask);
      }
    },

    //切换入职类型时的规则
    specialDate(value) {
      console.log("切换规则", value);
    },

    recordPageSizeChange(value) {
      this.taskRecord.size = value;
      this.getNewTaskRecord(this.taskRow)
    },
    recordPageChange(value) {
      this.taskRecord.page = value;
      this.getNewTaskRecord(this.taskRow)
    },
    //获取客户群
    getCustomerGroup() {
      let url =
          "/scrm-api/we-work/contact-group?limit=10" +
          "&cursor=" +
          this.cursor;
      this.FetchGet(url).then((res) => {
        let groupList = [];
        console.log('haha', res)
        groupList = res.group_chat_list;
        /* this.groupChatList=res.data.group_chat_list*/
        groupList.forEach((item) => {
          this.$set(item, "checked", false);
          this.groupChatList.push(item);
        });
        console.log(this.groupChatList);
        this.cursor = res.next_cursor;
      });
    },
    lookMoreQun() {
      this.getCustomerGroup();
    },
    SelectCustomerQun() {
      this.SelectCustomerQunDialogVisible = false;
      this.$nextTick(() => {
        console.log(this.$refs.selectCustomerQun.showGroup);
        let ids = [];
        if (this.$refs.selectCustomerQun.showGroup !== undefined) {
          for (
              let i = 0;
              i < this.$refs.selectCustomerQun.showGroup.length;
              i++
          ) {
            ids.push(
                this.$refs.selectCustomerQun.showGroup[i].chat_id
            );
          }
        }
        this.groupIds = ids;
      });
    },
    openSelectCustomerQun() {
      this.SelectCustomerQunDialogVisible = true;
      this.$nextTick(() => {
        let groupShow = [];
        console.log(this.groupChatList);
        for (let i = 0; i < this.groupIds.length; i++) {
          this.groupChatList.forEach((item) => {
            if (this.groupIds[i] === item.chat_id) {
              this.$set(item, "checked", true);
              groupShow.push(item);
            }
          });
        }
        this.$refs.selectCustomerQun.showGroup = groupShow;
      });
    },
  },

  mounted() {
  },
  filters: {
    channel(value) {
      if (value === "WE_WORK") return "企业微信";
      if (value === "DING_TALK") return "钉钉";
    },
    crodType(value) {
      if (value === "EMPLOYEE") return "员工";
      if (value === "CUSTOMER") {
        return "客户";
      }
      if (value === "CUSTOMER_GROUP") {
        return "客户群";
      }
    },
    sendNumber(value) {
      if (value !== undefined) {
        if (value.crowd === "EMPLOYEE") {
          if (value.weWorkEmployeeConfig && value.weWorkEmployeeConfig.userIds) {
            return value.weWorkEmployeeConfig.userIds.length;
          }
        }
      }
    },

  },
  watch: {
    userTags: {
      handler(tags, oldTags) {
        console.debug("user tags changed", tags);
        this.employeeConfigUserIds = [];
        if (tags != null && tags.length > 0) {
          this.changeOrgChildrenProperties(tags, (item) => {
            if (item.isMember == true) {
              this.employeeConfigUserIds.push(item.id);
            }
          })
          this.employeeConfigUserIds = [...new Set(this.employeeConfigUserIds)]

        }
      },
      deep: true
    }
  }
};
</script>

<style lang="less">
// @import url("./../../../assets/less/fissionMarketing/massSend.less");
@import "../../assets/less/fissionMarketing/massSend";

#taskSend {
  display: flex;

  .selectMember {
    .el-dialog {
      margin-top: 100px !important;
    }
  }

  .content-container-roleManage {
   /* position: absolute;
    left: 64px;*/
    height: calc(100vh - 28px);
  /*  width: calc(100vw - 90px);*/
    text-align: left;
    background-color: #F6F8FD;
    padding: 14px;
    flex: 1;
    .roleManage-content {
      height: calc(100vh - 105px);
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      .roleManage-content-left{
        flex: none;
        width: 210px;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        height: calc(100vh - 112px);
        margin-right: 16px;
        .side-bat-list{
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          .side-bar-item{
            padding: 14px 0 14px 0;
            cursor: pointer;
            i{
              color: #616161;
              padding-right:10px;
            }
          }
          .side-bar-item-name{
            padding: 4px 0 4px 24px;
            border-left: 2px solid #ffffff;
          }
          .active-side-bar{
            border-left: 2px solid #366AFF;
            color: #366AFF;
            i{
              color: #366AFF!important;
            }
          }
        }
      }
      .roleManage-content-right{
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        // height: calc(100vh - 112px);
        flex: 1;
        position: relative;
        display: flex;
      }
      #left-sub-nav-tab {
        .el-tabs__nav {
          width: 150px;
          border: none;

          .el-tabs__item {
            text-align: center !important;
            height: 48px;
            line-height: 48px;
            border: none;
          }
        }

        .el-tabs__nav-scroll {
          background-color: white;
          height: calc(~"100vh - 60px");
        }
      }

      .left-content {
        width: 150px;
        flex: none;
      }

      .right-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 180px);
        width: calc(100vw - 320px);
        background-color: white;
        .newCustomer{
          margin-top: 14px;
          .alert-text{
            background-color: #fdf6ec;
            color: #E6A23C;
            padding: 0px 16px;
            border-radius: 4px;
            display: flex;
            align-items: center;

          }
        }
      }

      .operation-button {
        .el-switch.is-disabled {
          opacity: 1 !important;
        }

        .el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
          cursor: pointer !important;
        }
      }
    }


    .steps1 {
      margin-top: 30px;

      .form-item {
        text-align: left;
        margin: 15px 0;

        .label {
          margin-right: 5px;
        }
      }
    }
  }

  .roleManage-header {
   /* padding: 8px 16px;
    background: #f5f8fb;
    -webkit-box-shadow: 0 2px 10px -5px #d1dfec;
    box-shadow: 0 2px 10px -5px #d1dfec;
    border-bottom: 1px solid #d1dfec;
    height: 40px;
    display: flex;
    justify-content: space-between;*/
    height: 62px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .header-handle {
      margin-top: 4px;
      margin-right: 8px;
    }

    .leftInfo {
      display: flex;
      align-items: center;
      .headerName {
        font-size: 16px;
        color: #303133;
        margin-left: 8px;
      }
      .split-line{
        width: 2px;
        background-color: #366aff;
        margin: 0 12px;
        height: 14px;
      }
      .header-tip{
        font-size: 14px;
        color: #999999;
      }
      .guoran-tongyichicun-renwuguanli{
        font-size: 20px;
      }
    }

    .rightButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* .el-tabs__active-bar {
       height: 2px !important;
   }*/
  .bind-bot-self-tree {
    height: calc(100vh - 400px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.tast-list-box {
  .task-pagination {
    position: absolute;
    right: 40px;
    bottom: 10px;
  }
}

.bind-bot-self-tree-box {
  text-align: left;
  flex: auto;
  position: relative;
  line-height: 28px;
  height: 56px;
  padding: 0;

  .selected-box {
    border: solid 1px #e4e7ed;
    height: 56px;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;

    .tag-box {
      height: 56px;
      flex: auto;

      .placeholder {
        height: 56px;
        line-height: 56px;
        color: #c0c4cc;
        margin-left: 8px;
      }

      .el-tag {
        margin-left: 6px;
      }
    }

    .handle-teg-box {
      flex: none;
      width: 15px;
      margin-left: 10px;
      line-height: 100%;
      cursor: pointer;
      position: absolute;
      right: 1px;
      top: 20px;

      span {
        height: 10px;
        flex: none;
      }
    }
  }

  .bind-bot-self-tree {
    padding-top: 5px;
    padding-left: 6px;
    z-index: 99999999999999999;
    position: relative;
    top: 3px;
    background-color: white;
    border-left: solid 1px #e4e7ed;
    border-right: solid 1px #e4e7ed;
    border-bottom: solid 1px #e4e7ed;
    border-radius: 6px;
    max-height: 400px;
    overflow: auto;
    min-height: 200px;
  }

}

.bind-bot-self-tree {
  height: calc(100vh - 400px);
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
